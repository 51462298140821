import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'

import Layout from '../components/layout'
import ProductRange from '../components/product-range'

import Entrance from './entrance-door.png'
//import French from './sliding-sash.png'
//import Bifold from './sliding-sash.png'
//import Sliding from './sliding-sash.png'
import Beadbutt from './bead-butt-door.png'

const Doors = () => (
  <Layout>
    <Helmet title="Doors | Engineered to be stylish, modern and secure | Rudd Joinery">
      <meta name="description" content="Our range of high performance bespoke timber doors are manufactured using the very best engineered timber and hardware to provide an excellent balance of stylish joinery and modern secure design" />
    </Helmet>
    <div
      className="hero"
      style={{ backgroundImage: 'url(/static/images/doors-hero.jpg)' }}
    >
      <header
        data-sal-duration="600"
        data-sal="fade"
        data-sal-delay="300"
        data-sal-easing="ease-out-bounce"
      >
        <h1>Doors</h1>
        <h2>Engineered to be stylish, modern and secure</h2>
      </header>
    </div>
    <div className="section">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <p>
            Our range of high performance bespoke timber doors are manufactured
            using the very best engineered timber and hardware to provide an
            excellent balance of stylish joinery and modern secure design.
          </p>
        </div>
      </div>
    </div>
    <div className="features">
      <div className="wrapper">
        <h3>Our door range features</h3>
        <ul>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <path d="M81.14,67.13a32.84,32.84,0,1,0,30.54,20.69A32.87,32.87,0,0,0,81.14,67.13Z" />
              <path d="M100,0A100,100,0,1,0,200,100,100,100,0,0,0,100,0Zm71.6,145.5v6.38h-6.14V145.5H133.88V146a6.81,6.81,0,0,1-6.79,6.78H75.74a6.63,6.63,0,0,1-2.87-.65,52.74,52.74,0,1,1,60.36-60.35,6.71,6.71,0,0,1,.65,2.87V137H171.6v8.48Z" />
            </svg>
            Made to measure
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <path d="M100,0A100,100,0,1,0,200,100,100,100,0,0,0,100,0ZM85.26,65.49a34,34,0,0,0-5.08-.36,34.88,34.88,0,0,0,0,69.75,34,34,0,0,0,5.08-.37v15.42c-1.67.16-3.36.25-5.08.25A50.18,50.18,0,1,1,85.26,50.07Zm84.75,0a34,34,0,0,0-5.08-.36A34.87,34.87,0,0,0,130.88,92.5H160v15H130.88a34.87,34.87,0,0,0,34.05,27.38,34,34,0,0,0,5.08-.37v15.42c-1.67.16-3.36.25-5.08.25A50.18,50.18,0,1,1,170,50.07Z" />
            </svg>
            CE Marked
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <path d="M100,57.57A19.82,19.82,0,0,0,80.18,77.39V96.56h39.64V77.39A19.82,19.82,0,0,0,100,57.57Z" />
              <path d="M100,0A100,100,0,1,0,200,100,100,100,0,0,0,100,0Zm41.66,146.43a7.65,7.65,0,0,1-7.65,7.65H66a7.65,7.65,0,0,1-7.65-7.65V104.21A7.65,7.65,0,0,1,66,96.56h2.54V77.39a31.47,31.47,0,1,1,62.94,0V96.56H134a7.65,7.65,0,0,1,7.65,7.65Z" />
            </svg>
            Secure design
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <path d="M100,0A100,100,0,1,0,200,100,100,100,0,0,0,100,0ZM83.2,146.48s-30.59-45.34-32.81-46.09l7.81-7.81,24.22,18.75c29.69-39.85,57.81-57,57.81-57h10.16C127.73,73.05,87.74,135.64,83.2,146.48Z" />
            </svg>
            Quality assured
          </li>
          <li>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
              <circle cx="100.1" cy="43.79" r="5.08" />
              <path d="M100,0A100,100,0,1,0,200,100,100,100,0,0,0,100,0Zm39.62,146.08s-.41,12.72-8.16,17.21H52.33c7.75-4.49,8.16-17.21,8.16-17.21V116.3h79.13Zm0-38.1H60.54V93.87a5.35,5.35,0,0,1,3.1-4.08c2.81-1.27,18.84-6.61,18.84-6.61s6.35-.71,7.43-12.08C91.25,56.83,86,51.48,86.24,41.57,86.5,28.7,100.1,28.71,100.1,28.71s13.61,0,13.87,12.86c.21,9.91-5,15.26-3.67,29.53,1.07,11.37,7.43,12.08,7.43,12.08s16,5.34,18.84,6.61a5.35,5.35,0,0,1,3.1,4.08Z" />
            </svg>
            Range of finishes, ironmongery, glass and mouldings
          </li>
        </ul>
      </div>
    </div>
    <ProductRange productRange="doors" heroImage={Entrance}>
      <h2>Engineered to be solid and secure</h2>
      <p>
        The <strong>Entrance Door</strong> is one of the first things you notice
        about a home, so you want to make sure yours says the right things about
        you and your property. We design and manufacture beautiful high
        performance doorsets.
      </p>
      <p>
        <Link to="/doors/entrance" className="cta">
          Explore the Entrance Door
        </Link>
      </p>
    </ProductRange>
    <ProductRange productRange="doors">
      <h2>Stylish and fashionable</h2>
      <p>
        French doors have remained fashionable over the years and our
        hand-crafted <strong>French doors</strong> combine the elegance inherent
        in a French door with the performance features of today whether you
        decide to have full height glass or one of our panel and glass
        variations, these doors can really make a feature of your property to
        give a practical entry onto your garden or patio.
      </p>
      <p>
        <Link to="/doors/french" className="cta">
          Explore the French Door
        </Link>
      </p>
    </ProductRange>
    <ProductRange productRange="doors">
      <h2>A style statement creating socialable spaces</h2>
      <p>
        Our bespoke <strong>Bifold</strong> doorsets can help you make a style
        statement and create sociable spaces. The door stacks neatly and
        compactly to the side, creating an open-plan area as well as the option
        to divide spaces, such as between a house and a conservatory during the
        evenings.
      </p>
      <p>
        <Link to="/doors/bifold" className="cta">
          Explore the Bifold Door
        </Link>
      </p>
    </ProductRange>
    {/* <ProductRange productRange="doors">
      <h2>Effortless smooth gliding action</h2>
      <p>
        
      </p>
      <p>
        <Link to="/doors/sliding" className="cta">
          Explore the Sliding Door
        </Link>
      </p>
    </ProductRange> */}
    <ProductRange productRange="doors" heroImage={Beadbutt}>
      <h2>Traditional styling</h2>
      <p>
        A popular choice for period properties as well as refurbishment projects
        and new builds, our Norfolk <strong>Bead and Butt</strong> doors are a traditional style
        of door that can add a timeless touch to a property.
      </p>
      <p>
        <Link to="/doors/bead-and-butt" className="cta">
          Explore the Bead &amp; Butt
        </Link>
      </p>
    </ProductRange>
    <a href="/contact-us" className="cta-bar ovalroomblue">
      <div>
        <strong>To receive a no obligations quote click here</strong>
      </div>
    </a>
    <div className="section lightgrey eco-friendly">
      <div className="wrapper">
        <div
          className="text-block story"
          data-sal-duration="600"
          data-sal="slide-up"
          data-sal-delay="300"
          data-sal-easing="ease-out-bounce"
        >
          <h2 className="heading ico eco">Eco-friendly</h2>
          <p>
            We only use wood sourced from managed sustainable forests and from
            the UK wherever possible. Many of our products are made from <a href="https://www.accoya.com">Accoya&reg;</a>
            timber, a naturally renewable and award-winning brand that reduces
            environmental impact and sourced from FSC&reg; woods.
          </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default Doors
